import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Loader from './components/loader';
import ProtectedRoute from './ProtectedRoute';

const Login = lazy(() => import('./components/login'));
const Submission = lazy(() => import('./components/submission'));
const Project = lazy(() => import('./components/project'));
const Domain = lazy(() => import('./components/domain'));
const Tech = lazy(() => import('./components/tech'));
const Admin = lazy(() => import('./components/admin'));

const App = () => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <CssBaseline />
        <Switch>
          <Route exact path="/" component={Login} />
          <ProtectedRoute path="/submission" component={Submission} />
          <ProtectedRoute path="/project" component={Project} />
          <ProtectedRoute path="/domain" component={Domain} />
          <ProtectedRoute path="/tech" component={Tech} />
          <ProtectedRoute path="/admin" component={Admin} />
          <Route render={() => <Redirect to={{ pathname: '/' }} />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  </GoogleOAuthProvider>
);

export default App;
